exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-price-js": () => import("./../../../src/pages/price.js" /* webpackChunkName: "component---src-pages-price-js" */),
  "component---src-pages-works-ehlektrika-pod-klyuch-js": () => import("./../../../src/pages/works/ehlektrika-pod-klyuch.js" /* webpackChunkName: "component---src-pages-works-ehlektrika-pod-klyuch-js" */),
  "component---src-pages-works-index-js": () => import("./../../../src/pages/works/index.js" /* webpackChunkName: "component---src-pages-works-index-js" */),
  "component---src-pages-works-montazh-ehlektroprovodki-js": () => import("./../../../src/pages/works/montazh-ehlektroprovodki.js" /* webpackChunkName: "component---src-pages-works-montazh-ehlektroprovodki-js" */),
  "component---src-pages-works-ustanovka-bytovoj-tekhniki-js": () => import("./../../../src/pages/works/ustanovka-bytovoj-tekhniki.js" /* webpackChunkName: "component---src-pages-works-ustanovka-bytovoj-tekhniki-js" */),
  "component---src-pages-works-vyzvat-ehlektrika-na-dom-js": () => import("./../../../src/pages/works/vyzvat-ehlektrika-na-dom.js" /* webpackChunkName: "component---src-pages-works-vyzvat-ehlektrika-na-dom-js" */)
}

